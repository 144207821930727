html, body {
    height: 100%;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background-image: url("greenLight.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    background-attachment: fixed;
    transition: background-image 3s;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.container-md {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 1400px) {
    .container-md {
        max-width: 1350px;
    }
}

/* header */
#headerNav {
    transition: background-color .5s;
}

.bg-solid {
    background-color: #364543;
}
.bg-trans {
    background-color: transparent;
}

.navbar-brand {
    font-family: 'Julius Sans One', sans-serif;
    font-size: 30px;
    font-weight: 300;
}

/* about page */
#main-bio {
    opacity: 0;
    transition: opacity 5s;
    color: rgba(255,255,255,0.9);
}

#main-bio .card {
    max-width: 1100px;
    margin: 5em 0;
}

#main-bio h2 {
    letter-spacing: 1px;
}

#main-bio .card p {
    font-size: 21px;
    text-align: justify;
}

#skillIcons {
    margin: 5em 0;
}

#skillIcons .col-md-3, #skillIcons .col-xs-4 {
    text-align: center;
    padding-top: 15px;
}

#skillIcons .col-lg-2 {
    text-align: center;
    padding: 15px 0px;
}

#skillIcons  p {
    margin-top: 11px;
    margin-bottom: 0;
}

#main-bio .card-img {
    box-shadow: 0 5px 8px -6px #17141F, 0 -4px 8px -6px #17141F;
}

/* portfolio page */
#portfolio {
    min-height: 100vh;
}

#portfolio .row .card {
    opacity: 0;
    transition: opacity 5s;
}

.card-footer {
    background: linear-gradient(
        to top,
        #9AEAA8,
        rgba(154, 234, 168, .9)
    );
    box-shadow: inset 0 0 20px #41C463, 0 5px 8px -6px #17141F, 0 -4px 5px -6px #17141F;
    margin-top: -10em;
}

.card-footer h4 {
    color: #206E38;
    font-variant: small-caps;
}

.card-footer .btn {
    color: #32a14e;
}

.card-footer .btn :hover {
    color: #206E38;
}

.card-footer  p {
    color: #206E38;
    height: 5em;
    text-align: center;
    transition: 1s ease;
}

#toolBox {
    height: 42px;
}

#toolBox svg, #toolBox i {
    color: #32a14e;
}

#portfolio .card-img {
    box-shadow: 0 -4px 8px -6px #17141F;
    cursor: pointer;
}

#portfolio .card-img:hover {
    animation-name: openCard;
    animation-delay: .6s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

@keyframes openCard {
    0% {margin-top: 0em; margin-bottom: 0em;}
    50% {margin-top: -10em; margin-bottom: 10em;}
    100% {margin-top: 0em; margin-bottom: 0em; z-index: 99; box-shadow: 0 5px 8px -6px #17141F, 0 -4px 8px -6px #17141F;}
}

#portfolio .card-footer {
    z-index: 10;
}

/* footer */

#footer p {
    font-size: 10px;
}

#footer li:hover {
    color: rgba(255,255,255,0.9);
}

#footer li {
    width: 6rem;
}